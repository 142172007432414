<template>
  <div class="face_wrap">
    <div class="center_inner">
      <img class="face-bg" src="@/assets/image/face-bg.png" alt="">
      <h4>{{loading ? '正在' : ''}}接入人脸识别页面</h4>
      <div class="video_box">
        <img class="scangif" v-show="isnotbtn && !isExtract" src="@/assets/image/face.gif" alt="">
        <a id="downLoadLink" style="display: none;"></a>
        <div class="tips-bg">
          <p class="tips" v-show="isnotbtn && !isExtract">{{actionTxt}}</p>
        </div>
        <video
          v-show="isnotbtn"
          ref="video"
          class="videoClass"
          id="myVideo" muted playsinline
        ></video>
        
        <canvas  class="videoClass" id="myCanvas" />
        <canvas ref="canvas" width="400" height="300"  v-show="false" />
        <!-- 截图展示图片 -->
        <img  v-show="false" ref="screenshot">
        <!-- <div class="pan-thumb" v-show="isnotbtn">
          <div class="center"></div>
        </div> -->
        <!-- 未识别到摄像头时图片替换 -->
        <img v-show="!isnotbtn || isExtract" class="errorImg" src="@/assets/image/headImg2.png" alt="">
      </div>
      <div class="font">
        <p class="p1" v-show="!loading && !isnotbtn">摄像头打开失败</p>
        <p class="p1" v-show="isnotbtn && !isExtract">{{isFace ? '正在提取人脸信息，请勿移动' : '请正视摄像头，露出面部'}}</p>
        <p class="p1" v-show="isExtract">面部信息提取完毕，正在进行校验</p>
        <p class="p2">本次操作遵守国家法律法规，采集的信息仅用于此次考试</p>
      </div>
    </div>
    <a-modal :maskClosable="false" v-model="isExamCodeShow" @cancel="cancelModal">
      <div class="modal-box">
        <h3>{{isnotbtn?'人脸识别不通过':'摄像头打开失败'}}</h3>
        <div class="means-item">
          <span>方式一</span>
          <div class="content-inner">
            <div class="head-top">
              <img src="@/assets/image/icon7.png" alt="">
              <div class="side-r">
                <span>调整摄像头，再次识别</span>
                <p>请把摄像头对准您的人脸部位，或检测您的摄像头是否开启权限，是否连接。</p>
              </div>
            </div>
            <a-button type="primary" class="btn" @click="callCamera">再试一次</a-button>
          </div>
        </div>
        <div class="means-item">
          <span>方式二</span>
          <div class="content-inner two-inner">
            <div class="head-top" >
              <img src="@/assets/image/icon7.png" alt="">
              <div class="side-r">
                <span>人工信息识别</span>
                <p>点击下方“人工审核”按钮，点击后，请保持在当前页面耐心等待。工作人员将会尽快处理您的审核请求。</p>
              </div>
            </div>
            <!-- <div class="phone-bottom">
              <p>工作人员联系方式：</p>
              <div class="mobile">
                <span v-for="item in linkData" :key="item.tellId">{{item.name}}：{{item.mobile}}</span>
              </div>
              <div class="input-code">
                <span class="codeText">考试码：</span>
                <a-input class="input" placeholder="考试码" :maxLength=6 v-model="examCode" />
              </div>
            </div> -->
            <a-button type="primary" class="btn" :loading="loadBtn" @click="FunCheckCode">人工审核</a-button>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal :maskClosable="false" v-model="isManualReview" width="581px" @cancel="cancelModal">
      <div class="modal-box">
        <div class="means-item">
          <div class="content-inner two-inner" style="margin-top: 24px;">
            <p class="head-title">
              <span>{{reviewStatus}}</span>
            </p>
            <div class="head-top">
              <div class="side-r" v-if="manualReviewData=='0000'">
                <p>审核中，请保持在当前页面耐心等待。</p>
                <p>工作人员将会尽快审核。若在5分钟内未得到处理，请您拨打电话联系工作人员。</p>
                <p>请勿离开或切换页面，以免影响审核进程。感谢您的配合与等待。</p>
              </div>
              <div class="side-r" v-else-if="manualReviewData=='6666'">
                <p>点击下方按钮进入考试</p>
              </div>
              <div class="side-r" v-else >
                <p>原因：{{manualReviewData}}</p>
              </div>
            </div>
            <div class="phone-bottom" v-if="manualReviewData!='6666'">
              <p>联系方式：</p>
              <div class="mobile">
                <span v-for="item in linkData" :key="item.tellId">{{item.name}}：{{item.mobile}}</span>
              </div>
            </div> 
            <a-button type="primary" class="btn" :loading="loadBtn" @click="entryExamClick" v-if="manualReviewData==6666">进入考试</a-button>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as faceapi from "face-api.js";
import { FunError } from '@/utils/fun.js'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      isnotbtn: false,
      loading: true,
      isContrastLoading: false,  // 是否处于比对
      faceCount: 0,  // 比对的次数
      downSecond: 7, 
      isExamCodeShow: false,
      isManualReview: false, // 人工审核
      demo: '',
      nets: "tinyFaceDetector", // 模型
      withBoxes: false,  // 框or轮廓
      options: null, // 模型参数
      detectFace: "detectSingleFace", // 单人脸检测     多人脸 detectAllFaces  
      videoEl: null,  // 视频dom
      canvasEl: null,  // 画布dom
      timeout: 0,
      isFace:false,  // 是否检测到人脸

      video_stream: '', // 视频stream
      isOnce:true,
      isExtract:false,  // 是否已完成提取人脸信息
      faceForm:{
        imageUrl: this.$store.state.userInfo.certifimobile,
        videoUrl:'',
        validateData: '' //动作顺序
      },
      errorNum:0,
      linkData:[],
      examCode:'',  // 考试码
      loadBtn:false,
      action: [], //人脸活体识别动作
      actionTxt: '',
      intervalId: null,
      manualReviewData:null,
      reviewStatus:"审核中"
    }
  },
  // 事件处理器
  methods: {
    // 校验考试码
    FunCheckCode(){
      let obj = this.$store.state.userInfo;
      this.loadBtn = true;
      this.$ajax({
        method: 'put',
        url:"/exam/system/submitManualReview/"+obj.examineeId
      }).then(res => {
        this.loadBtn = false
        if (res.code == 200 && res.success) {
          this.isExamCodeShow = false;
          this.isManualReview = true;
          this.startPolling();
        }
      })
    },
    getManualReviewStatus(){
      let obj = this.$store.state.userInfo;
      this.loadBtn = true;
      this.$ajax({
        method: 'get',
        url:"/exam/system/getManualReviewStatus/"+obj.examineeId
      }).then(res => {
        this.loadBtn = false
        if (res.code == 200 && res.success && res.data == "6666") {
          this.isManualReview = true;
          this.manualReviewData = res.data;
          this.reviewStatus = "已通过";
        } else {
          this.reviewStatus = (res.data != "6666"&&res.data != "0000"?"未通过":"审核中");
        }
      })
    },
    cancelModal(){
      // this.$router.go(-1)
      this.$router.push({ path: "/ExamView" });
    },
    // 调用摄像头
    callCamera() {
      this.faceCount = 0

      this.downSecond = 7

      this.loading = true
      this.isnotbtn = false
      this.isExtract = false
      // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      this.isExamCodeShow = false
      // H5调用电脑摄像头API
      navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then((success) => {
        this.loading = false
        this.isnotbtn = true;
        // 摄像头开启成功
        this.$refs["video"].srcObject = success;
        // 实时拍照效果
        this.$refs["video"].play();
        this.video_stream = success;
        setTimeout(() => {
        }, 500); // 开始绘画人脸追踪
        
        this.actionStep() // 动作步骤
        setTimeout(() => this.record(), 1000); // 1秒后开始录制
        setTimeout(() => this.stop(), 8000); // 7秒后开始校验
      })
      .catch((error) => {
        clearTimeout(this.timeout);

        this.loading = false
        let obj = this.$store.state.userInfo
        obj.isDisable = false
        this.$store.commit("updateUserInfo", obj);  // 更新个人信息
        setTimeout(() => {
          this.isExamCodeShow = true;
          this.getManualReviewStatus()
          // 若摄像头打开失败，增加异常操作记录
          if(!this.isnotbtn) this.funWatchError(11,2);
        }, 1000); // 摄像头打开失败 打开弹窗
      });
    },

    // 动作步骤
    actionStep(){
      this.timer1 = setInterval(()=>{
        this.downSecond --
        if(this.downSecond > 3){
          this.actionTxt = this.action[0]
        } else {
          this.actionTxt = this.action[1]
        }
        if(this.downSecond == 0){
          clearTimeout(this.timer1);
          
        }
      }, 1000)
    },

    // 停止录制
    stop() {
      if (!this.$refs["video"].srcObject) return;
      const stream = this.$refs["video"].srcObject;
      const tracks = stream.getTracks();
      // 关闭摄像头和音频
      tracks.forEach(track => {
        track.stop();
      });
    },

    // 视频录制
    record() {
      let mediaRecorder;
      let options;
      this.recordedBlobs = [];
      if (typeof MediaRecorder.isTypeSupported === 'function') {
        // 根据浏览器来设置编码参数
        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
          options = {
            MimeType: 'video/webm;codecs=h264',
          };
        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
          options = {
            MimeType: 'video/webm;codecs=h264',
          };
        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
          options = {
            MimeType: 'video/webm;codecs=vp8',
          };
        }
        mediaRecorder = new MediaRecorder(this.video_stream, options);
      } else {
        console.log('当前不支持isTypeSupported，使用浏览器的默认编解码器');
        mediaRecorder = new MediaRecorder(this.video_stream);
      }
      mediaRecorder.start();
      // 视频录制监听事件
      mediaRecorder.ondataavailable = e => {
        // console.log(e);
        // 录制的视频数据有效
        if (e.data && e.data.size > 0) {
          this.recordedBlobs.push(e.data);
        }
      };
      // 停止录像后增加下载视频功能，将视频流转为mp4格式
      mediaRecorder.onstop = () => {
        const blob = new Blob(this.recordedBlobs, { type: 'video/mp4' });
        this.recordedBlobs = [];
        // 将视频链接转换完可以用于在浏览器上预览的本地视频
        const videoUrl = window.URL.createObjectURL(blob);
        // 设置下载链接
        document.getElementById('downLoadLink').href = videoUrl;
        // 设置下载mp4格式视频
        document.getElementById('downLoadLink').download = 'media.mp4';
        document.getElementById('downLoadLink').innerHTML = 'DownLoad video file';
        // 生成随机数字
        const rand = Math.floor((Math.random() * 1000000));
        // 生成视频名
        const name = `video${rand}.mp4`;
        // setAttribute() 方法添加指定的属性，并为其赋指定的值
        document.getElementById('downLoadLink').setAttribute('download', name);
        document.getElementById('downLoadLink').setAttribute('name', name);
        this.isExtract = true

        this.actionTxt = ''
        
        this.$upload({
          file: blob,
          progress: (e)=>{
          },
          success: (e)=>{
            this.faceForm.videoUrl = e
            this.FaceVerification() // 去对比
          }
        })
        // 0.5s后自动下载视频
        // setTimeout(() => {
        //   document.getElementById('downLoadLink').click();
        // }, 500);
      };
    },

    // 活体人脸对比校验
    FaceVerification(){
      let obj = this.$store.state.userInfo
      this.$ajax({
        method: 'post',
        url:"/exam/system/face?imageUrl=" + this.faceForm.imageUrl + '&videoUrl=' + this.faceForm.videoUrl + '&validateData=' + this.faceForm.validateData + '&examineeId=' + obj.examineeId,
      }).then(res => {
        this.closeCamera()  // 关闭摄像头
        if (res.code == 200 && res.success) {  // 对比通过
          obj.isDisable = false
          obj.isPassed = true
          obj.entyMode = 1  // 活体对比
          this.$store.commit("updateUserInfo", obj);  // 更新个人信息

          this.$message.success('识别成功');

          this.$router.push({ path: "/ExamView" });
        } else {
          this.$message.error('人脸对比失败，请再次尝试');
          this.funWatchError(8, 2)  // 记录失败信息
          this.errorNum ++
          if(this.errorNum < 2){
            obj.isDisable = false
            this.$store.commit("updateUserInfo", obj);  // 更新个人信息
            this.isnotbtn = true;  // 对比失败提醒
            setTimeout(()=>{
              this.callCamera()  // 重新打开摄像头
            },2000)
          } else{
            setTimeout(()=>{
              this.$router.replace({ path: "/ExamView/ExamFaceImage" });
            },3000)
          }

          // this.isExamCodeShow = true   // 打开错误弹窗

          // obj.isDisable = false
          // this.$store.commit("updateUserInfo", obj);  // 更新个人信息

          // this.isnotbtn = true;  // 对比失败提醒
        }
      })
    },

    // 监听异常记录
    funWatchError(type, status){
      if(this.loading) return
      this.$ajax({
        method: "POST",
        url: "/exam/abnor-oper/save?content=" + FunError(type) + '&examineeId=' + this.$store.state.userInfo.examineeId + '&status=' + status,
      })
    },

    // 关闭摄像头
    closeCamera() {
      if (!this.$refs["video"].srcObject) {
        this.dialogCamera = false;
        return;
      }
      let stream = this.$refs["video"].srcObject;
      let tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      this.$refs["video"].srcObject = null;
      this.isnotbtn = false;
    },

    // 获取人脸动作
    getFaceOrder(){
      this.$ajax({
        method: 'get',
        url:"/exam/system/face-action",
        params: {}
      }).then(res => {
        if (res.code == 200 && res.success) {
          let action = res.data.actionSequence.split(",")
          if(action[0] == '1'){
            this.action = ['请张张嘴', '请眨眨眼']
          } else {
            this.action = ['请眨眨眼', '请张张嘴']
          }
          this.faceForm.validateData = res.data.actionSequence
        }
      })
    },

    // 获取联系人
    getLinkList(){
      this.$ajax({
        method: 'get',
        url:"/exam/system/tell-list",
        params:{
          configId: this.$store.state.userInfo.configId,
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.linkData = res.data
        }
      })
    },
    async fetchAuditStatus(){
      this.$ajax({
        method: 'get',
        url:"/exam/system/getManualReviewStatus/"+this.$store.state.userInfo.examineeId
      }).then(res => {
        if (res.code == 200 && res.success) {
         this.manualReviewData = res.data;
         this.reviewStatus= (res.data=="6666"?"已通过":(res.data=="0000"?"审核中":"未通过"));
         if(this.manualReviewData!='0000'){
          this.stopPolling();
         }
        }
      })
    },
    startPolling() {
      // 设置定时器，每分钟调用fetchData方法
      this.intervalId = setInterval(this.fetchAuditStatus, 10000);
      // 立即执行一次请求
      this.fetchAuditStatus();
    },
    stopPolling() {
      // 清除定时器
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    entryExamClick(){
      var obj = this.$store.state.userInfo;
      obj.isSubmit = false  // 是否提交试卷
      this.$store.commit("updateUserInfo", obj);  // 更新个人信息
      this.$router.push({ path: "/ExamView/ExamInfo" });
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.getFaceOrder();
    // 页面停留半分钟如果未反应将跳到照片比对再次尝试
    this.timeout = setTimeout(()=>{
      this.closeCamera()  // 关闭摄像头
      // this.isExamCodeShow = true   // 打开错误弹窗

      // let obj = this.$store.state.userInfo
      // obj.isDisable = false
      // this.$store.commit("updateUserInfo", obj);  // 更新个人信息

      // this.isnotbtn = true;  // 对比失败提醒

      this.$router.replace({ path: "/ExamView/ExamFaceImage" });
    },40000)
  },
  // 生命周期-实例挂载后调用
  mounted () { 
    this.$nextTick(() => {
      this.callCamera()
      this.getLinkList()
    });
  },
  // 生命周期-实例销毁前调用
  beforeDestroy () {
    // this.videoEl.pause();
    clearTimeout(this.timeout);
    clearTimeout(this.timer1);
    this.closeCamera();
    this.stopPolling();
  },
  // 生命周期-实例销毁离开后调用
  destroyed () {
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.face_wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .center_inner{
    text-align: center;
    width: 600px;
    height: 600px;
    position: relative;
    .face-bg{
      position: absolute;
      left: 0;
      top: 0;
      width: 600px;
      height: 600px;
      z-index: -1;
    }
    h4{
      margin-top: 100px;
      font-size: 24px;
      font-weight: 500;
      color: #000000;
    }
    .face_box{
      margin: 20px auto;
    }
  }
}
.video_box{
  margin: 95px auto 20px;
  overflow: hidden;
  width: 217px;
  height: 217px;
  position: relative;
  text-align: center;
  .scangif{
    width: 200px;
    margin-top: 11px;
    margin-left: 8px;
  }
  .tips-bg{
    position: absolute;
    top: 0;
    z-index: 56;
    overflow: hidden;
    width: 100%;
    width: 209px;
    height: 209px;
    // border-radius: 50%;
    .tips{
      // background: rgba(0,0,0,0.2);
      height: 30px;
      line-height: 30px;
      font-size: 20px;
      color: #ffffff;
      margin-top: 10px;
      margin-left: 15px;
    }
  }
  .videoClass {
    transform: rotateY(180deg);
    width: 209px;
    height: 209px;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    left: 4px;
    top: 4px;
    z-index: -2;
  }

  /* 视频边框旋转加载边框 */
  .pan-thumb {
    position: absolute;
    left: 0;
    top: 0;
    width:217px;
    height:217px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: linear-gradient(rgba(45, 116, 221, 1),rgba(255, 255, 255, 1));
    animation: rotate 1s infinite linear;
  }
  .pan-thumb:hover {
    transform: rotate(-100deg);
  }
  .pan-thumb>.center{
    position: absolute;
    z-index: 50;
    width: 208px;
    height: 208px;
    border-radius: 50%;
    background-color: #ffffff;
  }
  .errorImg{
    width: 140px;
    margin-top: 40px;
    margin-left: 10px;
  }

  /* 渐变色背景旋转动画 */
  @keyframes rotate {
    0% {
    transform: rotateZ(0);
    }
    100% {
    transform: rotateZ(360deg);
    }
  }
}



.font{
  margin-top: 40px;
  font-weight: 400;
  .p1{
    font-size: 22px;
    color: #333333;
    color: rgba(51,51,51,0.8)

  }
  .p2{
    margin-top: 10px;
    font-size: 14px;
    color: #999999;
  }

  .lamp{
    position: relative;
    bottom: 15px;
    animation: OpacityBreath 1s ease-in-out infinite;
    opacity: 1;
    color: rgb(238, 20, 20);
  }
  @keyframes OpacityBreath {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.modal-box{
  padding: 24px 40px 32px;
  >h3{
    font-weight: 500;
    color: #333333;
    font-size: 18px;
    text-align: center;
  }
  .means-item{
    >span{
      display: inline-block;
      font-weight: 500;
      color: #333333;
      margin-bottom: 5px;
    }
    .content-inner{
      background: #F8F8F8;
      padding: 24px 20px;
      margin-bottom: 24px;
      padding-right: 45px;
      text-align: center;
      .head-top{
        display: flex;
        img{
          width: 18px;
          height: 18px;
        }
        .side-r{
          text-align: left;
          flex: 1;
          margin-left: 8px;
          span{
            color: #333333;
          }
          p{
            font-size: 12px;
            color: #666666;
          }
        }
      }
      .btn{
        margin-top: 32px;
        min-width: 212px;
        height: 43px;
        background: linear-gradient(180deg, #3681F0 0%, #2263C5 100%);
      }
    }
    .two-inner{
      .head-top{
        padding-bottom: 12px;
      }
    }
    .phone-bottom{
      padding-top: 12px;
      font-size: 12px;
      text-align: left;
      margin-left: 25px;
      border-top: 1px solid #E6E7E8;
      color: #333333;
      .mobile{
        margin-top: 5px;
        color: #666666;
        overflow: hidden;
        span{
          float: left;
          width: calc(100% / 2);
        }
      }
      .input-code{
        margin-top: 15px;
        display: flex;
        align-items: center;
        .codeText{
          display: inline-block;
          width: 60px;
          color: #333333;
          font-size: 14px;
        }
        .input{
          width: 120px;
        }
      }
    }
  }
}
.head-title {
  margin-bottom: 12px;
}
/deep/ .ant-modal-header{
  display: none;
}
/deep/ .ant-modal-body{
  padding: 0;
}
/deep/ .ant-modal-footer{
  display: none;
}
</style>
